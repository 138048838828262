import axios from "@axios";
const {
  axiosRoutes: { calendar: path },
} = require("/G_CONFIG");
export default {
  namespaced: true,
  state: {
    userInfo: { name: "" },
  },
  mutations: {
    SET_USER_NAME(state, val) {
      val = localStorage.getItem("userName");
      state.userInfo.name = val;
      console.log("storum calısıyor" + val);
    },
  },
  getters: {},
  actions: {},
};
