const SERVER_PATH = "";
module.exports = {
	axiosRoutes: {
		subject: {
			new: `${SERVER_PATH}/subject`,
			getAll: `${SERVER_PATH}/subject`,
			getOne: `${SERVER_PATH}/subject`,
			delete: `${SERVER_PATH}/subject`,
			edit: `${SERVER_PATH}/subject`,
		},
		parent: {
			new: `${SERVER_PATH}/parent`,
			getAll: `${SERVER_PATH}/parent`,
			getOne: `${SERVER_PATH}/parent`,
			delete: `${SERVER_PATH}/parent`,
			edit: `${SERVER_PATH}/parent`,
			setPassword: `${SERVER_PATH}/parent/setPassword/`,
			reset: `${SERVER_PATH}/parent/resetPassword`,
		},
		address: {
			new: `${SERVER_PATH}/address`,
			getAll: `${SERVER_PATH}/address`,
			getOne: `${SERVER_PATH}/address`,
			update: `${SERVER_PATH}/address/`, //+`{id}` PUT,
			delete: `${SERVER_PATH}/address/`, //+`{id}` DELETE,
		},
		session: {
			get: `${SERVER_PATH}/remembrance`,
			delete: `${SERVER_PATH}/remembrance`,
		},
		payment: {
			new: `${SERVER_PATH}/payment`,
			getAll: `${SERVER_PATH}/payment`,
			// getOne: `${SERVER_PATH}/payment`,
		},
		productPackage: {
			new: `${SERVER_PATH}/productpackage`, // POST need auth
			getAll: `${SERVER_PATH}/productpackage`, // GET
			getOne: `${SERVER_PATH}/productpackage/`, // GET with id
			update: `${SERVER_PATH}/productpackage/`, // PUT with id need auth
			delete: `${SERVER_PATH}/productpackage/`, // DELETE with id need auth
		},
		profile: {
			edit: `${SERVER_PATH}/profile`,
			my: `${SERVER_PATH}/profile/my`,
			getOne: `${SERVER_PATH}/profile`,
			getAll: `${SERVER_PATH}/profile`,
		},
		calendar: {
			new: `${SERVER_PATH}/calendar`,
			getAll: `${SERVER_PATH}/calendar`,
			getOne: `${SERVER_PATH}/calendar`,
			delete: `${SERVER_PATH}/calendar`,
			edit: `${SERVER_PATH}/calendar`,
		},
		discount: {
			new: `${SERVER_PATH}/discountCoupon`,
			getAll: `${SERVER_PATH}/discountCoupon`,
			edit: `${SERVER_PATH}/discountCoupon/`, // put , append id
		},
		auth: {
			login: `${SERVER_PATH}/auth/login`,
			register: `${SERVER_PATH}/auth/register`,
			forgot: `${SERVER_PATH}/auth/forgot`,
			reset: `${SERVER_PATH}/auth/reset`,
			dashboardStats: `${SERVER_PATH}/auth/dashboardStats`,
			changePassword: `${SERVER_PATH}/auth/change`,
		},
		exercise: {
			new: `${SERVER_PATH}/exercises/`, // /:subjectId || POST method
			print: `${SERVER_PATH}/exercises/`, // /:id || GET method
			lean: `${SERVER_PATH}/exercises/lean/`, // /:id || GET method || returns params only
			result: `${SERVER_PATH}/exercise/addResult/`, // /:id || POST method
			resultList: `${SERVER_PATH}/exercise/addResultList`, //  POST method
			list: `${SERVER_PATH}/exercise/list/`, // /:subjectId || GET method
			stats: `${SERVER_PATH}/exercise/stats/`, // /:subjectId || GET method
			compare: `${SERVER_PATH}/exercise/compare/`, // /:subjectId || GET method
			check: `${SERVER_PATH}/exercise/checkExists/`, // /:uID || POST method
			getsubject: `${SERVER_PATH}/exercise/forsubject/`, // /:uID || GET method
			liveReqs: `${SERVER_PATH}/exercise/liveReqs/`, // /:uID || GET method
		},
		blog: {
			new: `${SERVER_PATH}/blog`, // POST
			getAll: `${SERVER_PATH}/blog`, // GET
			getOne: `${SERVER_PATH}/blog/`, // GET with id OR slug
			delete: `${SERVER_PATH}/blog/`, // DELETE with id , need permission
			edit: `${SERVER_PATH}/blog/`, // PATCH with id , need permission
		},
		blogComment: {
			new: `${SERVER_PATH}/blogComment/`, // POST with blogID
			getAll: `${SERVER_PATH}/blogComment/`, // GET with blogID
			getOne: `${SERVER_PATH}/blogComment/`, // GET with blogID and commentID
			delete: `${SERVER_PATH}/blogComment/`, // DELETE with blogID and commentID , need permission
			edit: `${SERVER_PATH}/blogComment/`, // PATCH with blogID and commentID , need permission
		},
		permission: {
			new: `${SERVER_PATH}/permission`,
			getAll: `${SERVER_PATH}/permission`,
			getOne: `${SERVER_PATH}/permission`, // /:id || GET method
			delete: `${SERVER_PATH}/permission`, // /:id || DELETE method
			edit: `${SERVER_PATH}/permission`, // /:id || PATCH method
		},
		user: {
			new: `${SERVER_PATH}/user`,
			getAll: `${SERVER_PATH}/user`,
			getOne: `${SERVER_PATH}/user`, // /:id || GET method
			my: `${SERVER_PATH}/user/my`,
			referrals: `${SERVER_PATH}/user/referrals`,
			// delete: `${SERVER_PATH}/user`,
			edit: `${SERVER_PATH}/user`, // /:id || PATCH method
		},
		approval: {
			new: `${SERVER_PATH}/approval`,
			getAll: `${SERVER_PATH}/approval`,
			getOne: `${SERVER_PATH}/approval`, // /:id || GET method
			// delete: `${SERVER_PATH}/user`,
			edit: `${SERVER_PATH}/approval`,
		},
		account: {
			get: `${SERVER_PATH}/account`,
		},
		admin: {
			auth: {
				login: `${SERVER_PATH}/admin/auth/login`,
				forgot: `${SERVER_PATH}/admin/auth/forgot`,
				reset: `${SERVER_PATH}/admin/auth/reset`,
			},
			admin: {
				new: `${SERVER_PATH}/admin/admin`,
				getAll: `${SERVER_PATH}/admin/admin`,
				getOne: `${SERVER_PATH}/admin/admin`,
				delete: `${SERVER_PATH}/admin/admin`,
				edit: `${SERVER_PATH}/admin/admin`,
			},
			user: {
				new: `${SERVER_PATH}/admin/user`,
				getAll: `${SERVER_PATH}/admin/user`,
				getOne: `${SERVER_PATH}/admin/user`,
				delete: `${SERVER_PATH}/admin/user`,
				edit: `${SERVER_PATH}/admin/user`,
			},
		},
		qr: {},
		test: {},
	},
};
