import axios from "axios";
import Vue from "vue";

// axios

// axios.defaults.baseURL = "http://localhost:3000";
// axios.defaults.headers.common["Authorization"] =
//   "Bearer " + localStorage.getItem("token");
const axiosIns = axios.create({
	// You can add your headers here
	// ================================
	// baseURL: "http://192.168.1.107:3000",
	// baseURL: "http://localhost:3000",
	// baseURL: "http://213.238.181.188:3000",
	baseURL: "https://app.dikotr.com/api",
	// timeout: 5000,
});
// axiosIns.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//   }
// );

Vue.prototype.$http = axiosIns;
export default axiosIns;
