import Vue from "vue";
import VueRouter from "vue-router";

import { isUserLoggedIn } from "@/auth/utils";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Anasayfa",
      },
    },
    {
      path: "/confirm-email/:token",
      name: "confirm-email",
      component: () => import("@/views/EmailConfirm.vue"),
      meta: {
        pageTitle: "Eposta Onayı",
        layout: "full",
      },
    },
    {
      path: "/api/:route",
      name: "api",
    },
    {
      path: "/testreport/:id/:testid",
      name: "testreport",
      component: () => import("@/views/Test/Report/TestReportPage.vue"),
    },
    {
      path: "/testcompare/:id/:testid",
      name: "testcompare",
      component: () => import("@/views/apps/user/report-view/TestCompareView.vue"),
      meta: {
        pageTitle: "Danışan",
        layout: "full",
      },
    },
    // {
    //   path: "/ApprovalRequest",
    //   name: "ApprovalRequest",
    //   component: () => import("@/views/pages/ApprovalRequest.vue"),
    // },
    /* {
      path: "/admin",
      name: "Admin - Home",
      component: () => import("@/views/AdminHome.vue"),
      meta: {
        pageTitle: "Anasayfa",
        breadcrumb: [
          {
            text: "Anasayfa",
            active: true,
          },
        ],
      },
    }, */
    {
      path: "/test",
      name: "test",
      component: () => import("@/views/SecondPage.vue"),
      meta: {
        pageTitle: "Second Page",
      },
    },
    {
      path: "/login",
      name: "auth-login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/forgot-password",
      name: "auth-forgot-password",
      component: () => import("@/views/ForgotPassword.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/reset-password/:token",
      name: "auth-reset-password",
      component: () => import("@/views/ResetPassword.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/register",
      name: "auth-register",
      component: () => import("@/views/Register.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "/lang",
      name: "lang",
      component: () => import("@/views/LangSelect.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/clientlist/",
      name: "clientlist",
      props: true,
      component: () => import("@/views/apps/user/users-list/UsersList.vue"),
      meta: {
        pageTitle: "Danışanlar",
      },
    },
    {
      path: "/my-payments",
      name: "my-payments",
      component: () => import("@/views/pages/MyPayments.vue"),
      meta: {
        pageTitle: "Satın Alımlarım",
      },
    },
    {
      path: "/all-payments",
      name: "all-payments",
      component: () => import("@/views/pages/AllPayments.vue"),
      meta: {
        pageTitle: "Satın Alımlar",
      },
    },
    {
      path: "/users-list",
      name: "users-list",
      component: () => import("@/views/pages/UsersList.vue"),
      meta: {
        pageTitle: "Satın Alımlar",
      },
    },
    /* Goes to subject exercies */
    {
      path: "/exercises-detail/:id",
      name: "exercises-detail",
      meta: {
        pageTitle: "Egzersiz",
      },
      component: () => import("@/views/apps/user/users-exercise/UsersExerciseView.vue"),
    },

    /* direct exercise entry */
    {
      path: "/exercises/",
      name: "enter-exercise",
      meta: {
        pageTitle: "Egzersizler",
      },
      component: () => import("@/views/apps/user/users-exercise/DirectUsersExercieEntry.vue"),
    },
    {
      path: "/exercises-entry/:id",
      name: "exercises-entry",
      meta: {
        pageTitle: "Egzersiz Giris",
      },
      component: () => import("@/views/apps/user/users-exercise/UsersExerciseEntry.vue"),
    },
    {
      path: "/live-exercise-entry/:uID",
      name: "live-exercise-entry",
      meta: {
        pageTitle: "Canlı Egzersiz Giriş",
      },
      component: () => import("@/views/apps/user/users-exercise/ExerciseLiveEntry.vue"),
    },
    {
      path: "/parent/:id",
      name: "parent-report",
      component: () => import("@/views/apps/user/parent-view/UsersView.vue"),
      meta: {
        pageTitle: "Danışan",
        layout: "full",
      },
    },
    {
      path: "/compare/:id",
      name: "compare-report",
      component: () => import("@/views/apps/user/compare-view/UsersView.vue"),
      meta: {
        pageTitle: "Kıyas",
      },
    },
    {
      path: "/report/:id",
      name: "subject-report",
      component: () => import("@/views/apps/user/report-view/UsersView.vue"),
      meta: {
        pageTitle: "Danışan",
        layout: "full",
      },
    },
    {
      path: "/clientlist/:id",
      name: "clientlist-detail",
      component: () => import("@/views/apps/user/users-view/UsersView.vue"),
      meta: {
        pageTitle: "Danışan",
      },
    },
    {
      path: "/clientlist/:id/edit",
      name: "clientlist-edit",
      component: () => import("@/views/apps/user/users-edit/UsersEdit.vue"),
      meta: {
        pageTitle: "Düzenle",
        breadcrumb: [
          {
            text: "Danışanlar",
            active: false,
            name: "clientlist-detail",
            params: { id: ":id" },
          },
        ],
      },
    },
    {
      path: "/charts-and-maps/charts/chartjs",
      name: "charts-chartjs",
      component: () => import("@/views/charts-and-maps/charts/chartjs/Chartjs.vue"),
      meta: {
        pageTitle: "Chartjs",
      },
    },
    {
      path: "/apps/calendar",
      name: "calendar",
      component: () => import("@/views/apps/calendar/Calendar.vue"),
    },
    {
      path: "/diko-faq",
      name: "diko-faq",
      meta: {
        pageTitle: "Bilgi Bankası",
      },
      component: () => import("@/views/pages/faq/Faq.vue"),
    },
    {
      path: "/logout",
      name: "logout",
      meta: {
        pageTitle: "Logout",
      },
    },
    {
      path: "/contact",
      name: "contact",
      meta: {
        pageTitle: "Iletisim",
      },
    },
    {
      path: "/diko-mainpage",
      name: "diko-mainpage",
      meta: {
        pageTitle: "Anasayfa",
      },
    },
    {
      path: "/diko-faq/edit/:id",
      name: "diko-faq-edit",
      meta: {
        pageTitle: "Faq Edit",
      },
      component: () => import("@/views/pages/faq/FaqEdit.vue"),
    },
    {
      path: "/financial-settings",
      name: "financial-settings",
      meta: {
        pageTitle: "Paketler",
      },
      component: () => import("@/views/pages/pricing/Pricing.vue"),
    },
    {
      path: "/account-settings/plans",
      name: "account-settings-plans",
      meta: {
        pageTitle: "Pricing",
      },
      component: () => import("@/views/pages/pricing/Pricing.vue"),
    },
    {
      path: "/account-settings",
      name: "account-settings",
      meta: {
        pageTitle: "Hesap Ayarlari",
      },
      component: () => import("@/views/pages/account-setting/AccountSetting.vue"),
    },
    {
      path: "/acl",
      name: "acl",
      meta: {
        pageTitle: "ACL",
      },
      component: () => import("@/views/extensions/acl/AccessControl.vue"),
    },
    {
      path: "/print-exercise/:id",
      name: "print-exercise",
      meta: {
        pageTitle: "Egzersiz Yazdır",
      },
      component: () => import("@/views/print-exercise/PrintExercise.vue"),
      props: true,
    },
    {
      path: "/testWIP",
      name: "testWIP",
      meta: {
        pageTitle: "WIP",
        layout: "full",
      },
      component: () => import("@/views/Test/TestPage.vue"),
    },
    {
      path: "/testtesting/:id",
      name: "test-testing",
      meta: {
        layout: "full",
      },
      component: () => import("@/views/Test/TestPage.vue"),
    },
    {
      path: "/test/example",
      name: "exampletest",
      meta: {
        layout: "full",
      },
      component: () => import("@/views/Test/ExampleTest.vue"),
    },
    {
      path: "/diko-test/:id",
      name: "Diko - Test",
      meta: {
        pageTitle: "DIKO TEST",
      },
      component: () => import(/* webpackChunkName: "DikoTest" */ "../views/Test/TestPage.vue"),
    },
    {
      path: "/printexercise/:id",
      name: "printexercise",
      meta: {
        pageTitle: "Egzersiz Yazdır",
      },
      component: () => import("@/views/excercise/PrintExercise.vue"),
    },
    {
      path: "/printexercise2",
      name: "printexercise2",
      meta: {
        pageTitle: "Egzersiz Yazdır 2",
      },
      component: () => import("@/views/apps/user/exercise-print/PrintExerciseMain.vue"),
    },
    {
      path: "/selim",
      name: "selim",
      meta: {
        pageTitle: "BABA",
      },
      component: () => import("@/views/dev/SelimTest.vue"),
    },
    /*
    Random components test page 
    */
    {
      path: "/devtestpage",
      name: "test",
      meta: {
        pageTitle: "Test",
      },
      component: () => import("@/views/Test/RandomCompTest.vue"),
    },
    {
      path: "/pages/blog/list",
      name: "pages-blog-list",
      component: () => import("@/views/pages/blog/BlogList.vue"),
      meta: {
        pageTitle: "Blog List",
        breadcrumb: [
          {
            text: "Pages",
          },
          {
            text: "Blog",
          },
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/pages/blog/:id",
      name: "pages-blog-detail",
      component: () => import("@/views/pages/blog/BlogDetail.vue"),
      meta: {
        pageTitle: "Blog Detail",
        breadcrumb: [
          {
            text: "Pages",
          },
          {
            text: "Blog",
          },
          {
            text: "Detail",
            active: true,
          },
        ],
      },
    },
    {
      path: "/pages/blog/edit/:id",
      name: "pages-blog-edit",
      component: () => import("@/views/pages/blog/BlogEdit.vue"),
      meta: {
        pageTitle: "Blog Edit",
        breadcrumb: [
          {
            text: "Pages",
          },
          {
            text: "Blog",
          },
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },
    {
      path: "/forms",
      name: "forms",
      component: () => import("@/views/pages/upload-forms/UploadForms.vue"),
      meta: {
        pageTitle: "Forms",
        breadcrumb: [
          {
            text: "Pages",
          },
          {
            text: "Forms",
            active: true,
          },
        ],
      },
    },
    {
      path: "/pages/blog/blog-new",
      name: "new-blog",
      component: () => import("@/views/pages/blog/BlogNew.vue"),
      meta: {
        pageTitle: "New Blog",
        breadcrumb: [
          {
            text: "New Blog",
            active: true,
          },
        ],
      },
    },
    {
      path: "/pages/perm/perm-check",
      name: "perm-check",
      component: () => import("@/views/pages/perm/Perms.vue"),
      meta: {
        pageTitle: "Perm Page",
      },
    },
    {
      path: "/pages/approvals",
      name: "approval-list",
      component: () => import("@/views/pages/Approval/List.vue"),
      meta: {
        pageTitle: "Approval List",
      },
    },
    {
      path: "/pages/approvals/profile/:id",
      name: "approval-profile",
      component: () => import("@/views/pages/Approval/Profile.vue"),
      meta: {
        pageTitle: "Approval Profile",
      },
    },
    {
      path: "/pages/approvals/account/:id",
      name: "approval-account",
      component: () => import("@/views/pages/Approval/Account.vue"),
      meta: {
        pageTitle: "Approval Account",
      },
    },
    {
      path: "/pages/pricelist",
      name: "admin-price-list",
      component: () => import("@/views/pages/PriceList/List.vue"),
      meta: {
        pageTitle: "Price List",
      },
    },
    {
      path: "/pg",
      name: "pg",
      component: () => import("@/views/pages/IyzicoTest.vue"),
      meta: {
        pageTitle: "IyzicoTest",
      },
    },
    {
      path: "/pages/perm/userperms/:id",
      name: "user-perms",
      component: () => import("@/views/pages/perm/userPerms.vue"),
      meta: {
        pageTitle: "Perm Page",
      },
    },
    {
      path: "/pages/after-payment",
      name: "after-payment",
      component: () => import("@/views/pages/purchase-callback/PurchaseCallback.vue"),
      meta: {
        pageTitle: "After Payment",
        layout: "full",
      },
    },
    {
      path: "/parent/createpassword/:id",
      name: "parent-password",
      component: () => import("@/views/pages/parent/ParentPassword.vue"),
      meta: {
        pageTitle: "Parent Password",
        layout: "full",
      },
    },
    {
      path:'/pages/admin/discounts',
      name:'admin-discount-list',
      component: () => import("@/views/pages/Discounts.vue"),
    },
    {
      path:'/pages/guide',
      name:'guide',
      component: () => import("@/views/pages/Guide.vue"),
    },
  ],
});
router.beforeEach((to, from, next) => {
  // console.log("beforeRoute : ", to.name);
  // if(to.name == 'api'){
  //   console.log('api')
  //   console.log(to)
  //   console.log(to.params)
  //   console.log(to.params.route)
  //   window.location.href = `https://app.dikotr.com:3000/${to.params.route}`
  //   return;
  // }
  const isLoggedIn = isUserLoggedIn();
  // console.log("isLoggedIn : ", isLoggedIn);
  // if (!canNavigate(to)) {
  // Redirect to login if not logged in
  if (!isLoggedIn && to.name !== "auth-login" && to.name !== "auth-register" && to.name !== 'exampletest' && to.name !== 'after-payment' && to.name !== 'parent-password' && to.name !== 'auth-forgot-password' && to.name !== 'auth-reset-password' && to.name !== 'confirm-email') {
    return next({ name: "auth-login", query: { redirect: to.fullPath } });
  }
  // console.log("from.meta : ", from.meta);
  // console.log(to);

  // If logged in => not authorized
  // return next({ name: "misc-not-authorized" });
  // }

  // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData();
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  // }

  return next();
});
router.afterEach((to, from) => {
  // console.log("RouterAfterEach", to.name);
  if (to.name != "home") {
    // console.log("not home");
    if (to.name == "clientlist-edit" && from.name == "clientlist") {
      if (from.meta?.breadcrumb.length > 0)
        from.meta.breadcrumb[from.meta.breadcrumb.length - 1].active = false;
      from.meta.breadcrumb = [
        ...from.meta?.breadcrumb,
        {
          to: to.fullPath.substring(0, to.fullPath.lastIndexOf("/")),
          text: "Danışan",
          active: false,
        },
      ];
    }

    if (from.meta.breadcrumb && from.meta.breadcrumb.length > 0) {
      // console.log("has from breadcrumb");
      if (
        from.meta.breadcrumb.filter((item) => item.to == to.fullPath).length > 0
      ) {
        const bcrm = from.meta.breadcrumb.slice(
          0,
          from.meta.breadcrumb.map((e) => e.to).indexOf(to.fullPath) + 1
        );
        console.log("bcrm", bcrm);
        bcrm[bcrm.length - 1].active = true;
        to.meta.breadcrumb = bcrm;
      } else {
        // console.log("not includes");
        if (from.name == "home") {
          to.meta.breadcrumb = [
            { text: to.meta.pageTitle, to: to.fullPath, active: true },
          ];
        } else {
          from.meta.breadcrumb[from.meta.breadcrumb.length - 1].active = false;
          to.meta.breadcrumb = [
            ...from.meta.breadcrumb,
            { text: to.meta.pageTitle, to: to.fullPath, active: true },
          ];
        }
      }
    } else {
      // console.log("no from breadcrumb");
      to.meta.breadcrumb = [
        { text: to.meta.pageTitle, to: to.fullPath, active: true },
      ];
    }
  }
  // console.log("TO : ", to);
  // console.log("FROM : ", from);
  // ? For splash screen
  // Remove afterEach hook if you are not using splash screen
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
